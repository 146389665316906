import api from '@common/api';

export const getPorts = () => {
  return api.get('/resource/city');
};

export const getContainerSizeTypes = ({ allTypes = false, isOpen45 }) => {
  return api.get('/order/common/box_size_type', {
    params: {
      type: Number(allTypes),
      is_open45: isOpen45,
    },
  });
};

export const getShipEnterprise = (params = {}) => {
  const { search } = params;
  return api.get('/order/common/ship_enter', {
    params: {
      s: search,
    },
  });
};

export const getYardList = ({ port, search, top = false }) => {
  return api.get('/resource/yard', {
    params: {
      scope: port,
      s: search,
      top: Number(top),
    },
  });
};

export const getShipAgentList = ({ search }) => {
  return api.get('/resource/ship/agent', {
    params: {
      s: search,
    },
  });
};

export const getShipAgentListByShipEnterprise = ({
  shipEnterpriseId,
  search,
}) => {
  return api.get('/dockserve/find/ship_agent', {
    params: {
      ship_enter_id: shipEnterpriseId,
      s: search,
    },
  });
};

export const getYardListByShipEnterprise = ({
  port,
  shipEnterpriseId,
  search,
  top = false,
}) => {
  return api.get('/dockserve/find/yard', {
    params: {
      scope: port,
      ship_enter_id: shipEnterpriseId,
      s: search,
      top: Number(top),
    },
  });
};

export const getWharfList = ({ portId }) => {
  return api.get('/resource/wharf/search/all', {
    params: {
      scope: portId,
    },
  });
};

export const getContainerGateInWharfList = ({ portId }) => {
  return api.get('/cargocvge/price/wharf', {
    params: {
      scope: portId,
    },
  });
};

export const getHarborList = ({ search }) => {
  return api.get('/order/common/dock', {
    params: {
      s: search,
    },
  });
};

export const getProductCategoryList = () => {
  return api.get('/order/common/getCategory');
};

export const getAdministrationPartitionList = ({ id }) => {
  const basePath = '/common/address/find';
  const path = id ? `${basePath}/${id}` : basePath;
  return api.get(path, {
    params: {
      type: -1,
    },
  });
};

export const getAllLevelPartitionList = ({ id }) => {
  return api.get('/stock/distribute/getAllLevel');
};

/**
 * @param {number} type // 1 英文 2 中文
 */
export const getShipNameList = ({ search, type }) => {
  return api.get('/order/common/ship_name', {
    params: {
      s: search,
      type,
    },
  });
};

export const getNewShipNameList = ({ search, type }) => {
  return api.get('/order/common/ship_name', {
    params: {
      s: search,
      data_type: type,
    },
  });
};

export const getCustomerListSearch = ({ search }) => {
  return api.get('/enter/search', {
    params: {
      s: search,
      type: 1,
    },
  });
};

export const getDriverNumbers = ({ enterId = 0, s }) => {
  return api.get('/common/info/search/car_info', {
    params: {
      enter_id: Number(enterId),
      s,
    },
  });
};

export const getEnterStaff = (s) => {
  return api.get('/client/enter/staff', {
    params: {
      name: s,
    },
  });
};

export const getAllArea = () => {
  return api.get('/stock/distribute/all_area');
};

export const getAllRow = (id) => {
  return api.get(`/stock/distribute/all_rows/${id}`);
};

export const getRowDetails = (id) => {
  return api.get(`/stock/distribute/all_position/${id}`);
};

export const putLockPosition = (id) => {
  return api.put('/stock/distribute/lock_position', {
    ids: [id],
  });
};

export const getContainerSize = () => {
  return api.get('/order/common/box_size_type');
};

export const getContainerOrderBoxCode = ({ search }) => {
  return api.get('/common/method/check_code', {
    params: {
      code: search,
    },
  });
};

export const getOrderSearch = ({ enter_id, tidan_code }) => {
  return api.get('/cargocvge/mage/order', {
    params: {
      enter_id,
      tidan_code,
    },
  });
};

/**
 * 获取当前用户绑定的堆场信息
 * https://yapi.56hello.cn/project/371/interface/api/2600
 */
export const getYardInfo = () => {
  return api.get('/stock/base/yard/info');
};
