export const urlSearchFormat = (search) => {
  search = search.replace('?', '');
  return search.split('&').reduce((res, item) => {
    const [key, val] = item.split('=');
    if (key in res) {
      if (!Array.isArray(res[key])) {
        res[key] = [res[key]];
      }
      res[key].push(val);
    } else {
      res[key] = val;
    }
    return res;
  }, {});
};
