import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UseRequestProvider } from 'xiangxin-design';

import Routes from './Routes';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const mapDispatch = (dispatch) => ({
  getLoggedUser: dispatch.user.getLoggedUser,
  getMenu: dispatch.menu.getMenu,
});

class App extends React.Component {
  static propTypes = {
    getLoggedUser: PropTypes.func.isRequired,
    getMenu: PropTypes.func.isRequired,
  };
  init = async () => {
    const { getLoggedUser, getMenu } = this.props;
    try {
      await getLoggedUser();
      await getMenu();
    } catch (error) {}
  };
  componentDidMount() {
    this.init();
  }
  render() {
    return (
      <BrowserRouter>
        <UseRequestProvider>
          <ScrollToTop />
          <Routes />
        </UseRequestProvider>
      </BrowserRouter>
    );
  }
}

export default connect(null, mapDispatch)(App);
