import api from '@common/api';

export const checkPhone = (s) => {
  if (!/^1[34578]\d{9}$/.test(s)) {
    return false;
  } else {
    return true;
  }
};
export const getStaffList = (data) => {
  return api.get('/client/enter/staff', {
    params: data,
  });
};
export const getUserList = (id) => {
  return api.get(`/client/enter/staff/${id}`);
};
export const getSmsCaptchaList = (data) => {
  return api.post('/sms', data);
};
export const getUserAccountList = (data) => {
  return api.get('/user/account/exist', {
    params: {
      ...data,
    },
  });
};
export const getEnterStaffList = (data) => {
  return api.post('/client/enter/staff', data);
};
export const updateEnterStaffList = (id, data) => {
  return api.put(`/client/enter/staff/${id}`, data);
};
// 获取联系人列表接口
export const getLinkmanList = (data) => {
  return api.get(`/client/enter/users`, {
    params: {
      ...data,
    },
  });
};