import {
  getShipNameList,
  getNewShipNameList,
  getShipEnterprise,
  getCustomerListSearch,
  getEnterStaff,
  getContainerOrderBoxCode,
  getOrderSearch,
} from '@common/api';
import { debounce } from 'lodash';

export const handleShipNameSearch = async (search) => {
  const { data, error_code } = await getShipNameList({ search });
  if (error_code) return Promise.reject();
  const options = data.map((item) => ({
    label: `${item.ship_name_en} - ${item.ship_name_cn}`,
    value: item.id,
  }));
  return Promise.resolve(options);
};

export const handleNewShipNameSearch = async (search, type) => {
  const { data, error_code } = await getNewShipNameList({ search, type });
  if (error_code) return Promise.reject();
  const options = data.map((item) => ({
    label: `${item.ship_name_en} - ${item.ship_name_cn}`,
    value: item.id,
  }));
  return Promise.resolve(options);
};

export const handleShipEnterpriseSearch = async (search) => {
  const { data, error_code } = await getShipEnterprise({ search });
  if (error_code) return Promise.resolve([]);
  const options = data.map((item) => ({
    label: `${item.ship_enter_englist} - ${item.ship_enter_chinese}`,
    value: item.ship_enter_id,
  }));
  return Promise.resolve(options);
};
export const handleSwitchCustomerSearch = async (search) => {
  const { data, error_code, error_msg } = await getCustomerListSearch({
    search,
  });
  if (error_code) return Promise.reject(error_msg);
  const options = data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  return Promise.resolve(options);
};

export const handleEnterStaffSearch = async (search) => {
  const { data, error_code, error_msg } = await getEnterStaff(search);
  if (error_code) return Promise.reject(error_msg);
  const options = data.users.map((item) => ({
    label: `${item.name}-${item.mobile}`,
    value: item.id,
  }));
  return Promise.resolve(options);
};

export const fetchIsBoxCode = async (search, callback) => {
  const { error_code, error_msg } = await getContainerOrderBoxCode({
    search,
  });
  if (error_code) {
    callback(new Error(error_msg));
    return Promise.reject(error_msg);
  } else {
    callback();
    return Promise.resolve();
  }
};

const fetchIsExistWithDebounce = debounce(fetchIsBoxCode, 300);

export const checkLadingBoxCodeIsExist = (search, callback) => {
  if (!search) {
    callback(new Error('请输入箱号'));
    return;
  } else {
    return fetchIsExistWithDebounce(search, callback);
  }
};

export const fetchTidanCode = async (id, code, cb) => {
  const { data, error_code } = await getOrderSearch({
    enter_id: id,
    tidan_code: code,
  });
  cb && cb(error_code ? null : data);
};

const fetchTidanCodeWithDebounce = debounce(fetchTidanCode, 500);

export const getTidanCodeInfo = (id, code, cb) => {
  if (id && code) {
    return fetchTidanCodeWithDebounce(id, code, cb);
  } else {
    return cb(null);
  }
};
