import api from '@common/api';

export const updateYardStock = (id, data) => {
  return api.put(`/stock/distribute/field/${id}`, data);
};

export const addYardStock = (data) => {
  return api.post('/stock/distribute/field', data);
};

export const deleteYardStock = (id) => {
  return api.delete(`/stock/distribute/field/${id}`);
};

export const getYardStockList = (data) => {
  return api.get('/stock/distribute', {
    params: data,
  });
};

export const postYardStock = (data) => {
  return api.post('/stock/distribute', data);
};

export const getYardStockInfo = (id) => {
  return api.get(`/stock/distribute/${id}`);
};

export const putYardStock = (data, id) => {
  return api.put(`/stock/distribute/${id}`, data);
};
export const putAreaOperationYardStock = (status, id) => {
  return api.put(`/stock/distribute/audit/${id}`, {
    status,
  });
};
export const getDistributeDetails = (id) => {
  return api.get(`/stock/distribute/getLevel/${id}`);
};

export const putBatchDisable = (id, data) => {
  return api.put(`/stock/distribute/batch_disable/${id}`, data);
};
export const putBatchEnable = (id, data) => {
  return api.put(`/stock/distribute/batch_enable/${id}`, data);
};
// 场位查询-列表
export const getDistributeSearch = (params) => {
  return api.get(`/stock/distribute/search`, { params });
};

/**
 * 取消进场订单
 * @param {*} ids number []   非必须  集装箱ID号
 * @returns
 */
export const cancelInOrderContainer = (ids) => {
  return api.post('/stock/in/order/doInOrderCancelation', {
    container_ids: ids,
  });
};

/**
 * 出场安排
 * @param {*} ids number []	  非必须  集装箱ID号
 * @param {*} type number	  非必须  操作类型{1: 安排出场,2: 取消安排出场}
 * @returns
 */
export const handleOutContainer = (ids, type) => {
  return api.post('/stock/distribute/containerOutArrangement', {
    container_ids: ids,
    op_type: type,
  });
};

/**
 * 集港放箱-小票下载
 * @param {*} ids number []	  非必须  集装箱ID号
 */
export const downloadContainerTicket = (ids) => {
  return api.post('/stock/distribute/containerReleaseStub', {
    container_ids: ids,
  });
};

export const areaDistribute = () => {
  return api.get('/stock/distribute/area_distribute');
};

/**
 * redis 读
 */
export const getRedisKey = (key) => {
  return api.get(`/common/method/redis/${key}`);
};

/**
 * redis 写
 */
export const postRedis = (key, data) => {
  return api.post('/common/method/redis', {
    key,
    data,
  });
};

/**
 * 获取一个航次的收箱计划
 * @param {*} id
 * @returns
 */
export const getBoxPlanByVoyageNumber = (id) => ({
  url: `/cargocvge/order/limit/marge/${id}`,
});

/**
 * 中台订单查询限制
 * @param {*} params
 * @returns
 */
export const getOrderLimit = (params) => {
  return api.post('/cargocvge/order/limit/than', params);
};
// 校验提单号是否存在
export const VerifyLadingNumberApi = (params) => {
  return api.get('/order/common/tidan_code', { params });
};