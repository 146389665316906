export const getYearRange = (range = 10) => {
  const currentYear = new Date().getFullYear();
  const options = [];
  for (let index = 0; index <= range; index++) {
    options.push(currentYear - index);
  }
  return options;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
