import styled from 'styled-components';
import { Affix, Layout } from 'antd';

const { Sider } = Layout;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const LayoutInner = styled(Layout)`
  min-height: 100%;
`;

export const LayoutSidebarAffix = styled(Affix)`
  background: #fff;
`;

export const SystemName = styled.h1`
  margin: 0 10px 10px;
  padding: 16px 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
`;

export const LayoutContent = styled(Layout.Content)`
  padding: 20px;
`;
