import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LayoutHeaderWrapper,
  LayoutHeaderLead,
  LogoWrapper,
  LogoImg,
  SloganWrapper,
  SloganImg,
  LayoutHeaderTail,
  UserInfoWrapper,
  EnterpriseName,
  UserName,
  LogoutButton,
} from './styled';

import LogoImgWhiteUrl from '@assets/images/logo-white.png';
import SloganImgWhiteUrl from '@assets/images/slogan-white.png';
import { RedirectToPassportLogout } from 'xiangxin-webC-react';

const mapState = (state) => ({
  user: state.user,
});

const LayoutHeader = ({ user }) => {
  const handleLogout = () => {
    RedirectToPassportLogout();
  };

  return (
    <LayoutHeaderWrapper>
      <LayoutHeaderLead>
        <LogoWrapper>
          <LogoImg src={LogoImgWhiteUrl} />
        </LogoWrapper>
        <SloganWrapper>
          <SloganImg src={SloganImgWhiteUrl} />
        </SloganWrapper>
      </LayoutHeaderLead>
      <LayoutHeaderTail>
        <UserInfoWrapper>
          <EnterpriseName>{user.enterpriseName}</EnterpriseName>
          <UserName>{user.name}</UserName>
          <LogoutButton onClick={handleLogout}>退出登录</LogoutButton>
        </UserInfoWrapper>
      </LayoutHeaderTail>
    </LayoutHeaderWrapper>
  );
};

LayoutHeader.propTypes = {
  user: PropTypes.shape({
    enterpriseName: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default connect(mapState)(LayoutHeader);
