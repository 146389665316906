import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Spin } from 'antd';
import DefaultLayout from './layout';

const PageNotFound = lazy(() => import('./containers/Exception/PageNotFound'));

const getInitialMenuPath = (menu) => get(menu, '0.menu.0.url', null);
const ContainerStaffManagement = lazy(() =>
  import('./containers/ContainerAccountManagement/StaffManagement'),
);
const ContainerStaffOperating = lazy(() =>
  import(
    './containers/ContainerAccountManagement/StaffManagement/StaffOperating'
  ),
);
const ContainerSiteManagement = lazy(() =>
  import('./containers/SiteManagement'),
);
const EntryOrderRegister = lazy(() => import('./containers/EntryRegister'));

const ContainerSiteAddManagement = lazy(() =>
  import('./containers/SiteManagement/SiteAction'),
);
// 进场订单管理
const EntryOrderManagement = lazy(() => import('./containers/EntryOrder/List'));
const YardInquire = lazy(() => import('./containers/YardInquire'));

const YardMap = lazy(() => import('./containers/YardMap'));
const PortManagement = lazy(() => import('./containers/PortManagement'));
const SiteActionsList = lazy(() => import('./containers/SiteActionsList'));

const DriverManagement = lazy(() =>
  import('./containers/DriverManagement/List'),
);

export const routeConfig = [
  {
    path: '',
    mate: {
      breadcrumb: '堆场管理',
    },
    children: [
      {
        path: '/yard',
        children: [
          {
            path: '/actions',
            component: SiteActionsList,
            mate: {
              breadcrumb: '集港管理',
            },
          },
          {
            path: '/site',
            component: ContainerSiteManagement,
            mate: {
              breadcrumb: '场位设置',
            },
          },
          {
            path: '/site/add',
            component: ContainerSiteAddManagement,
            mate: {
              breadcrumb: '添加区域',
            },
          },
          {
            path: '/site/edit/:id',
            component: ContainerSiteAddManagement,
            mate: {
              breadcrumb: '编辑区域',
            },
          },
          {
            path: '/inquire',
            component: YardInquire,
            mate: {
              breadcrumb: '场位查询',
            },
          },
          {
            path: '/map',
            component: YardMap,
            meta: {
              breadcrumb: '二维场图',
            },
          },
          {
            path: '/newMap',
            component: YardMap,
            meta: {
              breadcrumb: '二维场图',
            },
          },
        ],
      },
      {
        path: '/account',
        mate: {
          breadcrumb: '系统管理',
        },
        children: [
          {
            path: 'staff',
            component: ContainerStaffManagement,
            mate: {
              breadcrumb: '员工管理',
            },
          },
          {
            path: 'staff/add',
            component: ContainerStaffOperating,
            mate: {
              breadcrumb: '添加员工',
            },
          },
          {
            path: 'staff/edit/:id',
            component: ContainerStaffOperating,
            mate: {
              breadcrumb: '修改员工',
            },
          },
        ],
      },
      {
        path: '/order',
        mate: {
          breadcrumb: '进出场管理',
        },
        children: [
          {
            path: 'entryOrder',
            children: [
              {
                path: '',
                component: EntryOrderManagement,
                mate: {
                  breadcrumb: '进场订单',
                },
              },
            ],
          },
          {
            path: 'entryRegister',
            component: EntryOrderRegister,
            mate: {
              breadcrumb: '进场登记',
            },
          },
          {
            path: '/exitOrder',
            component: EntryOrderManagement,
            mate: {
              breadcrumb: '出场订单',
            },
          },
          {
            path: '/driver',
            component: DriverManagement,
            meta: {
              breadcrumb: '司机管理',
            },
          },
        ],
      },
    ],
  },
];
function routeRender(routes, pPath = '') {
  return (
    <>
      {routes.map(({ path, component, children }) => {
        const routePath = `${pPath}${
          !path || path.startsWith('/') ? '' : '/'
        }${path}`;
        return (
          <React.Fragment key={routePath}>
            {children && routeRender(children, routePath)}
            {component && (
              <Route path={routePath} component={component} exact />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
const Routes = () => {
  const menu = useSelector((state) => state.menu);
  const defaultRedirectMenuPath = getInitialMenuPath(menu);
  return (
    <DefaultLayout>
      <Suspense
        fallback={
          <div className="lazyLoading">
            <Spin size="large" />
          </div>
        }
      >
        <Switch>
          {defaultRedirectMenuPath ? (
            <Redirect from="/" to={defaultRedirectMenuPath} exact />
          ) : null}
          {routeRender(routeConfig)}
          {menu.length ? <Route path="*" component={PageNotFound} /> : null}
        </Switch>
      </Suspense>
    </DefaultLayout>
  );
};

export default Routes;
