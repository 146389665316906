import styled from 'styled-components';
import { blue5 } from '@common/styles';

export const LayoutHeaderWrapper = styled.div`
  display: flex;
  padding: 0 26px;
  height: 60px;
  background: ${blue5};
`;

export const LayoutHeaderLead = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const LogoWrapper = styled.div`
  display: flex;
`;
export const SloganWrapper = styled.div`
  display: flex;
  margin-left: 18px;
`;

export const LogoImg = styled.img`
  height: 20px;
`;

export const SloganImg = styled.img`
  height: 16px;
`;

export const LayoutHeaderTail = styled.div`
  display: flex;
  align-items: center;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  margin-right: 20px;
  color: #fff;
`;

export const EnterpriseName = styled.span`
  margin-right: 20px;
`;

export const UserName = styled.span`
  margin-right: 20px;
`;

export const LogoutButton = styled.a`
  color: #fff;
`;
